<template>
  <div class="form-container">
    <ValidationObserver ref="form">
      <!-- 대표서비스 입력 폼 -->
      <form>
        <fieldset>
          <legend>{{$t('content.accelerating.academy.Common.txt04')}}</legend>
          <div class="align-right">
            <!-- *는 필수입력 항목입니다. -->
            <em class="required-mark">*</em>{{ $t('content.networking.CompanyInfo.txt53') }}
          </div>
          <ul>
            <!-- 서비스명 -->
            <li
              v-if="accApplyPgmMngDetail.serviceNameYn"
              class="form-row">
              <ValidationProvider
                vid="001_service_name"
                :name="$t('content.accelerating.academy.ServiceInfo.txt01')"
                :rules="{ required: true }"
                v-slot="{ errors, failed }">
                <BaseInput
                  ref="001_service_name"
                  id="service_name"
                  :label="$t('content.accelerating.academy.ServiceInfo.txt01')"
                  v-model="accInfo.service_name"
                  :placeholder="$t('content.accelerating.academy.ServiceInfo.txt02')"
                  required
                  :invalid="failed"
                />

                <span class="input-error-desc">{{ errors[0] }}</span>
              </ValidationProvider>
            </li>

            <!-- 서비스 소개 -->
            <li
              v-if="accApplyPgmMngDetail.serviceIntroduceYn"
              class="form-row">
              <ValidationProvider
                vid="002_service_introduce"
                :name="$t('content.accelerating.academy.ServiceInfo.txt03')"
                :rules="{ required: true }"
                v-slot="{ errors, failed }">
                <BaseInput
                  ref="002_service_introduce"
                  id="service_introduce"
                  :label="$t('content.accelerating.academy.ServiceInfo.txt03')"
                  v-model="accInfo.service_introduce"
                  :placeholder="$t('content.accelerating.academy.ServiceInfo.txt04')"
                  required
                  maxlength="30"
                  :invalid="failed"
                />

                <!-- 30자까지 입력 가능합니다. -->
                <span class="input-desc">{{ $t('content.accelerating.academy.ServiceInfo.txt05', [30]) }}</span>
                <span class="input-error-desc">{{ errors[0] }}</span>
              </ValidationProvider>
            </li>

            <!-- 서비스 차별점 -->
            <li
              v-if="accApplyPgmMngDetail.serviceDiffrenceYn"
              class="form-row">
              <ValidationProvider
                vid="003_service_diffrence"
                :name="$t('content.accelerating.academy.ServiceInfo.txt06')"
                :rules="{ required: true }"
                v-slot="{ errors, failed }">
                <BaseTextarea
                  ref="003_service_diffrence"
                  id="service_diffrence"
                  :label="$t('content.accelerating.academy.ServiceInfo.txt06')"
                  v-model="accInfo.service_diffrence"
                  :placeholder="$t('content.accelerating.academy.ServiceInfo.txt07')"
                  required
                  maxlength="200"
                  :invalid="failed"
                />

                <span class="input-error-desc">{{ errors[0] }}</span>
              </ValidationProvider>
            </li>

            <!-- 서비스/채널 URL -->
            <li
              v-if="accApplyPgmMngDetail.serviceUrlYn"
              class="form-row">
              <BaseInput
                icon="homepage"
                id="service_url"
                :label="$t('content.accelerating.academy.ServiceInfo.txt08')"
                v-model="accInfo.service_url"
                :placeholder="$t('content.accelerating.academy.ServiceInfo.txt09')"
              />
            </li>

            <!-- SNS URL -->
            <li
              v-if="accApplyPgmMngDetail.serviceFacebookUrlYn"
              class="form-row">
              <BaseInput
                icon="sns"
                id="service_facebook_url"
                :label="$t('content.accelerating.academy.ServiceInfo.txt10')"
                v-model="accInfo.service_facebook_url"
                :placeholder="$t('content.accelerating.academy.CompanyInfo.txt26')"
              />
            </li>

            <!-- 홍보영상/기사 URL -->
            <li
              v-if="accApplyPgmMngDetail.promotionUrl1Yn"
              class="form-row">
              <BaseLabelElement
                id="promotion_url"
                :label="$t('content.accelerating.academy.ServiceInfo.txt11')"
              />
              <BaseInputElement
                icon="media"
                v-model="accPromotionUrlInfo[0].url"
                id="promotion_url"
                :placeholder="$t('content.accelerating.academy.ServiceInfo.txt12')"
              />

              <!-- 홍보영상/기사 URL - 추가 입력 정보 -->
              <ul
                v-if="accPromotionUrlInfo.length > 1"
                class="additional-info-wrap">
                <template
                  v-for="(additionalPromotionUrlInfo, index) in accPromotionUrlInfo"
                >
                  <li
                    v-if="index > 0"
                    :key="index"
                    class="additional-info">
                    <BaseLabelElement
                      :id="'promotion_url' + index"
                      :label="$t('content.accelerating.academy.ServiceInfo.txt11')"
                      class="input-title"
                    />
                    <BaseInputElement
                      icon="media"
                      v-model="additionalPromotionUrlInfo.url"
                      :id="'promotion_url' + index"
                      :placeholder="$t('content.accelerating.academy.ServiceInfo.txt12')"
                    />

                    <!-- 삭제 버튼 -->
                    <BaseButton
                      type="line"
                      size="small"
                      @click="deletePromotionUrlInfo(index)"
                    >{{$t('content.common.button.btn07')}}</BaseButton>
                  </li>
                </template>
              </ul>

              <div
                v-if="accPromotionUrlInfo.length < LIMIT_PROMOTION_URL_INFO"
                class="btn-wrap">
                <!-- 추가 버튼 -->
                <BaseButton
                  type="line"
                  size="medium"
                  @click="addPromotionUrlInfo"
                >{{$t('content.common.button.btn18')}}</BaseButton>
              </div>
              <!-- // 홍보영상/기사 URL - 추가 입력 정보 -->
            </li>
            <!-- // 홍보영상/기사 URL -->
          </ul>
        </fieldset>

        <!-- 버튼 -->
        <div class="btn-wrap type2">
          <BackButton
            @click="$emit('back')"
          />
          <BaseButton
            type="line"
            @click="$emit('save')"
          >{{$t('content.common.button.btn01')}}</BaseButton>
          <BaseButton
            type="line"
            @click="doPrev"
          >{{$t('content.common.button.btn03')}}</BaseButton>
          <BaseButton
            @click="doNext"
          >{{$t('content.common.button.btn02')}}</BaseButton>
        </div>
        <!-- // 버튼 -->
      </form>
      <!-- // 대표서비스 입력 폼 -->
    </ValidationObserver>
  </div>
</template>

<script>
import { ValidationObserver, ValidationProvider } from 'vee-validate';
import { mixin as mixinHelperUtils } from '@/common/helperUtils';
import BaseLabelElement from '@/components/base/BaseLabelElement.vue';
import BaseInputElement from '@/components/base/BaseInputElement.vue';
import BaseInput from '@/components/base/BaseInput.vue';
import BaseTextarea from '@/components/base/BaseTextarea.vue';
import BaseButton from '@/components/base/BaseButton.vue';
import BackButton from '@/components/base/BackButton.vue';

/**
 * Programs > 공모지원 & 공모지원내역 > 공모 지원 (탭) - 3단계 : 대표서비스
 */
export default {
  name: 'ProgramsApplyStep3',
  mixins: [mixinHelperUtils],
  components: {
    ValidationObserver, // https://logaretm.github.io/vee-validate/api/validation-observer.html
    ValidationProvider, // https://logaretm.github.io/vee-validate/api/validation-provider.html
    BaseLabelElement,
    BaseInputElement,
    BaseInput,
    BaseTextarea,
    BaseButton,
    BackButton,
  },

  props: {
    /**
     * Acc지원프로그램 관리 상세
     * @type {import('../dto/AccApplyPgmMngDetailDto').default}
     */
    accApplyPgmMngDetail: {
      type: Object,
      required: true,
    },
    /**
     * Acc지원 정보 - 지원정보 (회사정보, 서비스, 기타입력 등)
     * @type {import('../dto/apply/AccInfoDto').default}
     * @see AccApplyDto
     */
    accInfo: {
      type: Object,
      required: true,
    },
  },

  data() {
    /**
     * 홍보영상/기사 URL : accInfo.promotion_url1, accInfo.promotion_url2,
     *   accInfo.promotion_url3을 필터링, 배열 형태로 변환
     */
    let adjustedAccPromotionUrlInfo = [
      this.accInfo.promotion_url1,
      this.accInfo.promotion_url2,
      this.accInfo.promotion_url3,
    ]
      .filter((value) => value);

    if (adjustedAccPromotionUrlInfo.length === 0) {
      adjustedAccPromotionUrlInfo = [undefined];
    }

    return {
      /**
       * 홍보영상/기사 URL
       * @type {Object[]}
       */
      accPromotionUrlInfo: adjustedAccPromotionUrlInfo
        .map((value) => ({ url: value })),

      /**
       * 홍보영상/기사 URL 입력 개수 제한
       * @type {number}
       */
      LIMIT_PROMOTION_URL_INFO: 3,
    };
  },

  watch: {
    /**
     * 홍보영상/기사 URL : 배열을 accInfo.promotion_url1, accInfo.promotion_url2,
     *   accInfo.promotion_url3으로 변환
     */
    accPromotionUrlInfo: {
      deep: true,
      handler(newVal) {
        const [first, second, third] = newVal;
        this.accInfo.promotion_url1 = first ? first.url : undefined;
        this.accInfo.promotion_url2 = second ? second.url : undefined;
        this.accInfo.promotion_url3 = third ? third.url : undefined;
      },
    },
  },

  /**
   * beforeRouteEnter
   * @see {@link https://router.vuejs.org/guide/advanced/navigation-guards.html#in-component-guards In-Component Guards}
   */
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      if (vm.accApplyPgmMngDetail.isEmptyStep3()) {
        // 입력할 것이 없으면 스텝 건너뛰기
        if (from && from.name === 'ProgramsApplyStep4') {
          vm.doPrev();
        } else {
          vm.doNext();
        }
      }
    });
  },

  methods: {
    doNext() {
      this.validateForm(this.$refs.form) // mixinHelperUtils
        .then(() => {
          this.$router.push({
            name: 'ProgramsApplyStep4',
          });
        });
    },
    doPrev() {
      this.$router.push({
        name: 'ProgramsApplyStep2',
      });
    },

    /**
     * 홍보영상/기사 URL - 추가 입력 정보 추가
     */
    addPromotionUrlInfo() {
      if (this.accPromotionUrlInfo.length < this.LIMIT_PROMOTION_URL_INFO) {
        this.accPromotionUrlInfo.push({
          url: undefined,
        });
      }
    },
    /**
     * 홍보영상/기사 URL - 추가 입력 정보 삭제
     */
    deletePromotionUrlInfo(index) {
      if (index < this.LIMIT_PROMOTION_URL_INFO) {
        this.accPromotionUrlInfo.splice(index, 1);
      }
    },
  },
};
</script>
